import { ComponentOptions } from "../@types/util";

const MuiDialog: ComponentOptions<"MuiDialog"> = {
  styleOverrides: {
    paper: ({ ownerState, theme }) => {
      return {
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
        padding: 16,
        border: `1px solid ${theme.palette.primary.light}`,
        ...(!ownerState.fullScreen && { borderRadius: 16 }),
      };
    },
  },
  defaultProps: {
    fullWidth: true,
  },
};

const MuiDialogTitle: ComponentOptions<"MuiDialogTitle"> = {
  styleOverrides: {
    root: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.5,
      padding: 16,
    },
  },
};

const MuiBackdrop: ComponentOptions<"MuiBackdrop"> = {
  styleOverrides: {
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
};

const MuiDialogContent: ComponentOptions<"MuiDialogContent"> = {
  styleOverrides: {
    root: {
      padding: "16px !important", //must keep !important: https://github.com/mui/material-ui/issues/27851
    },
  },
};

const components = { MuiDialog, MuiDialogTitle, MuiDialogContent, MuiBackdrop };
export default components;
